import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Menu} from "antd";
import {DashboardOutlined, MoneyCollectOutlined, PullRequestOutlined, SettingOutlined, TransactionOutlined} from "@ant-design/icons";
import * as helper from "../../utils/helper";
import * as permissions from "../../utils/permissions";
import * as constants from "../../utils/constants";

const {SubMenu} = Menu;

class SidebarMenu extends Component {
  render() {
    const {
      header,
      staff,
      user,
      roles,
    } = this.props;

    if (this.props.user_type === constants.USER_TYPE_JBL) {
      return (
        <React.Fragment>
          <Menu className="san-dashboard-menu"
                theme="light"
                mode={this.props.is_mobile_view ? "inline" : "horizontal"}
                style={{
                  borderInlineEnd: "none",
                  flex: 1,
                  minWidth: 0,
                }}
            // openKeys={['transaction_menu']}
            // style={{ lineHeight: '25px' }}
            // openKeys={header.open_menu_keys}
            // selectedKeys={header.selected_menu_keys}
            // onClick={({key}) => {
            //   set_active_menu(undefined, key);
            // }}
          >
            {this.props.is_mobile_view &&
            <div className="san-logo-wrapper">
              <h3>
                <Link to='/'>
                  <img alt="Amahi Mart" src={require('../../assets/img/logo_sm.jpg')} style={{height: '45px'}}/>
                </Link>
              </h3>
            </div>
            }

            <SubMenu
              key="item_order"
              title={<React.Fragment><PullRequestOutlined/> <span>Order Management</span></React.Fragment>}>
              <Menu.Item key="item_order">
                <span>Sales Order</span>
                <Link to='/item_order/list/'/>
              </Menu.Item>

              <Menu.Item key="item_order_delivery">
                <span>Order Delivery</span>
                <Link to='/item_order_delivery/list/'/>
              </Menu.Item>

              {/*<Menu.Item key="item_order_payment">*/}
              {/*  <span>Client Bank Transaction</span>*/}
              {/*  <Link to='/item_order/payment/list/'/>*/}
              {/*</Menu.Item>*/}
            </SubMenu>

            <SubMenu
              key="item_order_report"
              title={<React.Fragment><PullRequestOutlined/> <span>Order Mgmt Report</span></React.Fragment>}>
              <Menu.Item key="item_order_debtor_report">
                <span>Debtor Report</span>
                <Link to='/item_order/debtor_report'/>
              </Menu.Item>
            </SubMenu>

          </Menu>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Menu className="san-dashboard-menu"
              theme="light"
              mode={this.props.is_mobile_view ? "inline" : "horizontal"}
              triggerSubMenuAction='click'
              style={{
                borderInlineEnd: "none",
                flex: 1,
                minWidth: 0,
              }}
          // openKeys={['transaction_menu']}
          // style={{ lineHeight: '25px' }}
          // openKeys={header.open_menu_keys}
          // selectedKeys={header.selected_menu_keys}
          // onClick={({key}) => {
          //   set_active_menu(undefined, key);
          // }}
        >
          {this.props.is_mobile_view &&
          <div className="san-logo-wrapper">
            <h3>
              <Link to='/'>
                <img alt="Amahi Mart" src={require('../../assets/img/logo_sm.jpg')} style={{height: '45px'}}/>
              </Link>
            </h3>
          </div>
          }

          {/*
          <Menu.Item key="dashboard">
            <DashboardOutlined/>
            <span>Dashboard</span>
            <Link to='/dashboard/'/>
          </Menu.Item>
*/}

          {/*
          <Menu.Item key="party_list">
            <DashboardOutlined/>
            <span>Party</span>
            <Link to='/party/list/'/>
          </Menu.Item>
*/}

          {helper.is_head_office_staff(staff) ?
            <SubMenu
              key="item_setup"
              title={
                <React.Fragment><SettingOutlined/> <span>Inventory</span></React.Fragment>
              }
            >
              <Menu.Item key="item_unit_list">
                <span>Item Unit</span>
                <Link to='/item/unit/list/'/>
              </Menu.Item>

              {/*            <Menu.Item key="item_group_list">
              <span>Item Group</span>
              <Link to='/item/group/list/'/>
            </Menu.Item>*/}

              <Menu.Item key="item_category_list">
                <span>Item Category</span>
                <Link to='/item/category/list/'/>
              </Menu.Item>

              <Menu.Item key="item_list">
                <span>Item</span>
                <Link to='/item/list/'/>
              </Menu.Item>

              {/*              <Menu.Item key="item_incentive_list">
                <span>Item Incentive</span>
                <Link to='/item/incentive/list/'/>
              </Menu.Item>*/}
            </SubMenu> :

            <Menu.Item key="item_list">
              <DashboardOutlined/>
              <span>Item</span>
              <Link to='/item/list/'/>
            </Menu.Item>
          }

          {/*{helper.is_head_office_staff(staff) &&*/}
          <SubMenu
            key="accounting"
            title={
              <React.Fragment><TransactionOutlined/> <span>Account</span></React.Fragment>
            }
          >
            {helper.is_head_office_staff(staff) &&
            <Menu.Item key="account_tree_list">
              <span>Account Head</span>
              <Link to='/account/tree/list/'/>
            </Menu.Item>}

            <Menu.Item key="bank_account_list">
              <span>Bank Account</span>
              <Link to='/bank_account/list/'/>
            </Menu.Item>

            {helper.is_head_office_staff(staff) ?
              <Menu.Item key="supplier_list">
                {/*<DashboardOutlined/>*/}
                <span>Supplier</span>
                <Link to='/supplier/list/'/>
              </Menu.Item> : null}

            <Menu.Item key="customer_list">
              {/*<DashboardOutlined/>*/}
              <span>Customer</span>
              <Link to='/customer/list/'/>
            </Menu.Item>

            <Menu.Item key="day_close">
              <span>Day Close</span>
              <Link to='/account/day_close/'/>
            </Menu.Item>
          </SubMenu>
          {/*}*/}

          <SubMenu
            key="item_order"
            title={<React.Fragment><PullRequestOutlined/> <span>Order Management</span></React.Fragment>}>
            <Menu.Item key="item_order">
              <span>Sales Order</span>
              <Link to='/item_order/list/'/>
            </Menu.Item>

            <Menu.Item key="item_order_delivery">
              <span>Order Delivery</span>
              <Link to='/item_order_delivery/list/'/>
            </Menu.Item>

            {helper.is_head_office_staff(staff) &&
            <Menu.Item key="item_order_payment">
              <span>Client Bank Transaction</span>
              <Link to='/item_order/payment/list/'/>
            </Menu.Item>
            }

            {helper.is_head_office_staff(staff) &&
            <Menu.Item key="agent_bank_account">
              <span>Agent Bank Account</span>
              <Link to='/agent_bank_account/list/'/>
            </Menu.Item>
            }
          </SubMenu>

          <SubMenu
            key="transaction_menu"
            title={
              <React.Fragment><MoneyCollectOutlined/> <span>Transaction</span></React.Fragment>
            }
          >

            {helper.is_head_office_staff(staff) &&
            <SubMenu
              key="purchase_menu"
              title={
                <React.Fragment><span>Purchase</span></React.Fragment>
              }
            >
              <Menu.Item key="purchase_invoice_create">
                <span>New Purchase</span>
                <Link to='/supplier/purchase_invoice/create/'/>
              </Menu.Item>

              <Menu.Item key="supplier_purchase_return_create">
                <span>Purchase Return</span>
                <Link to='/supplier/purchase_return/create/'/>
              </Menu.Item>
            </SubMenu>
            }

            <SubMenu
              key="sale_transaction_menu"
              title={
                <React.Fragment><span>Sales</span></React.Fragment>
              }
            >
              <Menu.Item key="sales_invoice_create">
                <span>New Sale</span>
                <Link to='/customer/sales_invoice/create/'/>
              </Menu.Item>

              <Menu.Item key="sale_return_create">
                <span>Sale Return</span>
                <Link to='/customer/sales_return/create/'/>
              </Menu.Item>

              {/*              <Menu.Item key="sale_return_create">
                <span>Sale Return</span>
                <Link to='/transaction/sale_return/create/'/>
              </Menu.Item>*/}

              {/*              <Menu.Item key="sale_create">
                <span>New Sale</span>
                <Link to='/transaction/sale/create/'/>
              </Menu.Item>*/}
            </SubMenu>

            <SubMenu
              key="stock_transfer_transaction_menu"
              title={
                <React.Fragment><span>Stock Transfer</span></React.Fragment>
              }
            >
              {helper.has_permission(this.props.permissions, permissions.STOCK_TRANSFER_CREATE) &&
              <Menu.Item key="stock_transfer_create">
                <span>Stock Transfer</span>
                <Link to='/transaction/stock_transfer/create/'/>
              </Menu.Item>}

              {/*              <Menu.Item key="stock_transfer_return_create">
                <span>Stock Transfer Return</span>
                <Link to='/transaction/stock_transfer_return/create/'/>
              </Menu.Item>*/}
            </SubMenu>

            <SubMenu
              key="payment_transaction_menu"
              title={
                <React.Fragment><span>Payment</span></React.Fragment>
              }
            >
              {/*
              {helper.is_mart_office_staff(staff) &&
              <Menu.Item key="jbs_office_payment_create">
                <span>Area Office Payment</span>
                <Link to='/transaction/jbl_office_payment/create/'/>
              </Menu.Item>}
*/}

              <Menu.Item key="payment_create">
                <span>New Payment</span>
                <Link to='/transaction/payment/create/'/>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="receipt_transaction_menu"
              title={
                <React.Fragment><span>Receipt</span></React.Fragment>
              }
            >
              {/*
              {helper.is_mart_office_staff(staff) &&
              <Menu.Item key="jbs_office_receipt_create">
                <span>Area Office Receipt</span>
                <Link to='/jbs_office/receipt/create/'/>
              </Menu.Item>}

              <Menu.Item key="receipt_create">
                <span>Normal Receipt</span>
                <Link to='/transaction/receipt/create/'/>
              </Menu.Item>
*/}

              <Menu.Item key="customer_receipt_create">
                <span>Customer Receipt</span>
                <Link to='/customer/receipt/create/'/>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="account_transaction_menu"
              title={
                <React.Fragment><span>Account</span></React.Fragment>
              }
            >
              <Menu.Item key="voucher_create">
                <span>Voucher</span>
                <Link to='/account/voucher/list/'/>
              </Menu.Item>
            </SubMenu>

            {/*            <SubMenu
              key="ibt_transaction_menu"
              title={
                <React.Fragment><span>IBT</span></React.Fragment>
              }
            >
              <Menu.Item key="ibt_create">
                <span>Cash/Bank Transfer</span>
                <Link to='/transaction/ibt/cash_bank_transfer/'/>
              </Menu.Item>
            </SubMenu>*/}

            {/*            <Menu.Item key="stock_demand">
              <span>Stock Demand</span>
              <Link to='/stock_demand/'/>
            </Menu.Item>*/}
          </SubMenu>

          <SubMenu
            key="report_menu"
            title={
              <React.Fragment><SettingOutlined/> <span>Report</span></React.Fragment>
            }
          >
            {helper.is_head_office_staff(staff) &&
            <SubMenu
              key="purchase_report_menu"
              title={
                <React.Fragment><span>Purchase Report</span></React.Fragment>
              }>
              <Menu.Item key="purchase_register">
                <span>Purchase Register</span>
                <Link to='/report/purchase_register/'/>
              </Menu.Item>

              <Menu.Item key="purchase_vat_register">
                <span>Purchase Vat Register</span>
                <Link to='/report/purchase_vat_register/'/>
              </Menu.Item>

              {/*              <Menu.Item key="purchase_return_register">
                <span>Purchase Return Register</span>
                <Link to='/report/purchase_return_register/'/>
              </Menu.Item>*/}
            </SubMenu>
            }

            <SubMenu
              key="sales_report_menu"
              title={
                <React.Fragment><span>Sales Report</span></React.Fragment>
              }>
              <Menu.Item key="sales_bill_register">
                <span>Sales Bill Register</span>
                <Link to='/report/sales_bill_register/'/>
              </Menu.Item>

              <Menu.Item key="sales_return_register">
                <span>Sales Return Register</span>
                <Link to='/report/sales_return_register/'/>
              </Menu.Item>

              <Menu.Item key="sales_vat_register">
                <span>Sales Vat Register</span>
                <Link to='/report/sales_vat_register/'/>
              </Menu.Item>

              <Menu.Item key="sales_materialised_list">
                <span>Sales Materialised View</span>
                <Link to='/report/sales_materialised_report/'/>
              </Menu.Item>

              {/*              <Menu.Item key="sales_incentive_register">
                <span>Incentive Register</span>
                <Link to='/report/incentive_register/'/>
              </Menu.Item>*/}
            </SubMenu>

            <SubMenu
              key="stock_report_menu"
              title={
                <React.Fragment><span>Stock Report</span></React.Fragment>
              }>
              <Menu.Item key="stock_transfer">
                <span>Stock Transfer Register</span>
                <Link to='/report/stock_transfer/'/>
              </Menu.Item>

              <Menu.Item key="stock_register">
                <span>Stock Register</span>
                <Link to='/report/stock_register/'/>
              </Menu.Item>

              <Menu.Item key="item_movement_register">
                <span>Item Movement Register</span>
                <Link to='/report/item_movement_register/'/>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="customer_ledger_balance">
              <span>Customer Ledger Balance</span>
              <Link to='/report/customer_ledger_balance/'/>
            </Menu.Item>

            <Menu.Item key="customer_ledger">
              <span>Customer Ledger</span>
              <Link to='/report/customer_ledger/'/>
            </Menu.Item>

            {/*            {helper.is_head_office_staff(staff) &&
              <Menu.Item key="customer_incentive">
                <span>Customer Incentive</span>
                <Link to='/report/customer_incentive/'/>
              </Menu.Item>
            }*/}

            {/*
            <Menu.Item key="jbl_office_ledger">
              <span>Area Office Ledger</span>
              <Link to='/report/jbl_office_ledger/'/>
            </Menu.Item>
*/}

            {/*{helper.is_head_office_staff(staff) &&*/}
            <SubMenu
              key="account_report_menu"
              title={
                <React.Fragment><span>Account Report</span></React.Fragment>
              }>
              <Menu.Item key="account_ledger">
                <span>Ledger Detail</span>
                <Link to='/report/ledger_detail/'/>
              </Menu.Item>

              <Menu.Item key="main_ledger_report">
                <span>Group Account</span>
                <Link to='/report/account/main_ledger/'/>
              </Menu.Item>

              <Menu.Item key="sub_ledger_report">
                <span>Sub Ledger</span>
                <Link to='/report/account/sub_ledger/'/>
              </Menu.Item>

              <Menu.Item key="voucher_report">
                <span>Voucher</span>
                <Link to='/report/account/voucher/'/>
              </Menu.Item>

              <Menu.Item key="trial_balance_report">
                <span>Trial Balance</span>
                <Link to='/report/trial_balance/'/>
              </Menu.Item>

              <Menu.Item key="profit_loss_report">
                <span>Profit Loss</span>
                <Link to='/report/profit_loss/'/>
              </Menu.Item>

              <Menu.Item key="balance_sheet_report">
                <span>Balance Sheet</span>
                <Link to='/report/balance_sheet/'/>
              </Menu.Item>
            </SubMenu>
            {/*}*/}

            {helper.is_head_office_staff(staff) &&
            <Menu.Item key="day_close_report">
              <span>Day Close Report</span>
              <Link to='/report/day_close/'/>
            </Menu.Item>}

{/*            <Menu.Item key="ibt_register_report">
              <span>IBT Register</span>
              <Link to='/report/ibt_register/'/>
            </Menu.Item>

            <Menu.Item key="ho_branch_ibt_reconciliation_report">
              <span>IBT Reconciliation</span>
              <Link to='/report/ho_branch_ibt_reconciliation/'/>
            </Menu.Item>*/}

            <Menu.Item key="day_book_report">
              <span>Day Book</span>
              <Link to='/report/day_book/'/>
            </Menu.Item>

            <Menu.Item key="audit_log_report">
              <span>Audit Log</span>
              <Link to='/report/audit_log/'/>
            </Menu.Item>

            {/*            {helper.is_head_office_staff(staff) &&
            <Menu.Item key="purchase_vat_register_report">
              <span>Purchase VAT Register</span>
              <Link to='/purchase/vat_register/'/>
            </Menu.Item>
            }

            {helper.is_head_office_staff(staff) &&
            <Menu.Item key="item_stock_summary_report">
              <span>Stock Summary</span>
              <Link to='/report/item_stock/summary/'/>
            </Menu.Item>
            }*/}

            {helper.is_head_office_staff(staff) ?
              <SubMenu
                key="order_management_report_menu"
                title={
                  <React.Fragment><span>Order Mgmt</span></React.Fragment>
                }
              >
                <Menu.Item key="item_order_debtor_report">
                  <span>Debtor Report</span>
                  <Link to='/item_order/debtor_report'/>
                </Menu.Item>

                <Menu.Item key="delivery_charge_report">
                  <span>Delivery Charge Report</span>
                  <Link to='/item_order/delivery_charge_report'/>
                </Menu.Item>

                <Menu.Item key="incentive_report">
                  <span>Incentive Report</span>
                  <Link to='/item_order/incentive_report/'/>
                </Menu.Item>

                <Menu.Item key="item_order_incentive">
                  <span>Client Incentive Record</span>
                  <Link to='/item_order/incentive/list/'/>
                </Menu.Item>
              </SubMenu>
              : null}

            {/*{helper.is_head_office_staff(this.props.staff) &&*/}
            <SubMenu
              key="jbl_reports_menu"
              title={
                <React.Fragment><span>JBL Reports</span></React.Fragment>
              }>
              <Menu.Item key="demand_loan_list">
                <span>Demand Loan List</span>
                <Link to='/report/jbl/demand_loan_list/'/>
              </Menu.Item>

              <Menu.Item key="disbursed_loan_list">
                <span>Disbursed Loan List</span>
                <Link to='/report/jbl/disbursed_loan_list/'/>
              </Menu.Item>

              {helper.is_head_office_staff(this.props.staff) &&
              <Menu.Item key="jbl_bill_payment">
                <span>Bill Payment Report</span>
                <Link to='/report/jbl_bill_payment/'/>
              </Menu.Item>
              }

              <Menu.Item key="item_demand_list">
                <span>Item Demand</span>
                <Link to='/item_demand/list/'/>
              </Menu.Item>
            </SubMenu>
            {/*}*/}
          </SubMenu>

          <SubMenu
            key="Audit"
            title={<React.Fragment><PullRequestOutlined/> <span>Audit</span></React.Fragment>}>
            {helper.is_head_office_staff(this.props.staff) &&
            <Menu.Item key="party_confirmation_letter">
              <span>Confirmation Letter Print</span>
              <Link to='/party_confirmation_letter_print/'/>
            </Menu.Item>
            }
          </SubMenu>

          <SubMenu
            key="settings_menu"
            title={
              <React.Fragment><SettingOutlined/> <span>Settings</span></React.Fragment>
            }
          >
            <SubMenu
              key="utility_menu"
              title={
                <React.Fragment><span>Utility</span></React.Fragment>
              }
            >
              {/*<Menu.Item key="notice_list">
              <span>Notice</span>
              <Link to='/utility/notice/list/'/>
            </Menu.Item>*/}

              {helper.has_role(roles, 'admin') &&
              <Menu.Item key="db_backup_list">
                <span>Backup</span>
                <Link to='/utility/db_backup/list/'/>
              </Menu.Item>}
            </SubMenu>

            <Menu.Item key="office_list">
              {/*<DashboardOutlined/>*/}
              <span>Office</span>
              <Link to='/office/list/'/>
            </Menu.Item>

            <Menu.Item key="fiscal_year_list">
              <span>Fiscal Year</span>
              <Link to='/fiscal_year/list/'/>
            </Menu.Item>

            <SubMenu
              key="hr_setup_menu"
              title={
                <React.Fragment><span>HR</span></React.Fragment>
              }
            >
              <Menu.Item key="staff_list">
                {/*<DashboardOutlined/>*/}
                <span>Staff</span>
                <Link to='/staff/list/'/>
              </Menu.Item>

              {helper.has_role(roles, 'admin') &&
              <Menu.Item key="department_list">
                <span>Department</span>
                <Link to='/department/list/'/>
              </Menu.Item>}

              {helper.has_role(roles, 'admin') &&
              <Menu.Item key="staff_designation_list">
                <span>Staff Designation</span>
                <Link to='/staff_designation/list/'/>
              </Menu.Item>}

              {helper.has_role(roles, 'admin') &&
              <Menu.Item key="job_type_list">
                <span>Job Type</span>
                <Link to='/job_type/list/'/>
              </Menu.Item>}

              {helper.has_role(roles, 'admin') &&
              <Menu.Item key="staff_group_list">
                <span>Staff Group</span>
                <Link to='/staff_group/list/'/>
              </Menu.Item>}
            </SubMenu>

            {helper.has_role(roles, 'admin') &&
            <Menu.Item key="godown_list">
              <span>GoDown</span>
              <Link to='/office/godown/list/'/>
            </Menu.Item>}

            {helper.has_role(roles, 'admin') &&
            <Menu.Item key="terminal_list">
              <span>Terminal</span>
              <Link to='/office/terminal/list/'/>
            </Menu.Item>}

            {/*{helper.has_role(this.props.roles, 'admin') &&
            <SubMenu
              key="opening_balance_menu"
              title={
                <React.Fragment><span>Opening Balance</span></React.Fragment>
              }
            >
              {helper.has_role(this.props.roles, 'admin') &&
              <Menu.Item key="customer_opening_balance_list">
                <span>Customer Opening Balance</span>
                <Link to='/customer/opening_balance/list/'/>
              </Menu.Item>}

              {helper.has_role(this.props.roles, 'admin') &&
              <Menu.Item key="supplier_opening_balance_list">
                <span>Supplier Opening Balance</span>
                <Link to='/supplier/opening_balance/list/'/>
              </Menu.Item>}

              {helper.has_role(this.props.roles, 'admin') &&
              <Menu.Item key="account_opening_balance_list">
                <span>Ledger Opening Balance</span>
                <Link to='/account/opening_balance/list/'/>
              </Menu.Item>}

              {helper.has_role(this.props.roles, 'admin') &&
              <Menu.Item key="item_stock_opening_balance_list">
                <span>Item Stock Opening Balance</span>
                <Link to='/item/stock_opening_balance/list/'/>
              </Menu.Item>}

              {helper.has_role(this.props.roles, 'admin') &&
              <Menu.Item key="jbl_office_opening_balance_list">
                <span>Area Office Opening Balance</span>
                <Link to='/jbl_office/opening_balance/list/'/>
              </Menu.Item>}
            </SubMenu>}
*/}
          </SubMenu>
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  user_type: state.user.user.user_type,
  staff: state.user.staff,
  roles: state.user.roles,
  header: state.header,
  permissions: state.user.permissions
});

export default connect(mapStateToProps)(withRouter(SidebarMenu));
